import './App.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Public/login';
import SignUp from './pages/Public/signUp';
import Home from './pages/Public/home';
import About from './pages/Public/about';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faGoogle, faFacebookF, faFontAwesome, faInstagram, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import Test from './components/Test/test';
import CameraPatch from './pages/Platform/Staff/cameraPatch';
import Profile from './pages/Platform/Staff/profile';


const Dashboard = lazy(() => import('./pages/Platform/Admin/dashboard'));
const StaffDashboard = lazy(() => import('./pages/Platform/Staff/dashboard'));
const PatchList = lazy(() => import('./pages/Platform//Staff/patchList'));
const LocManagement = lazy(() => import('./pages/Platform/Admin/locManagement'));
const Staff = lazy(() => import('./pages/Platform/Admin/staff'))
const Reports = lazy(() => import('./pages/Platform/Staff/reports'))
const Statistics = lazy(() => import('./pages/Platform/Admin/statistics'))
const ObjectType = lazy(() => import('./pages/Platform/Admin/objType'));
const ThankYou = lazy(() => import('./pages/Public/thankYou'));


library.add(fas, faTwitter, faGoogle, faFacebookF, faFontAwesome, faInstagram, faLinkedinIn, faGithub);

function App() {
  return (
    <div className="App">

      <Router>
        <Suspense fallback={<div>QRpatching...</div>}>
          <Routes>
            <Route exact path="/" element={
              <Home />
            }
            />
            <Route exact path="/about" element={
              <About />
            }
            />
            <Route path="/sign-in" element={
              <Login />
            }
            />
            <Route path="/sign-up" element={
              <SignUp />
            }
            />
            <Route path="/dashboard" element={
              <Dashboard />
            }
            />
            <Route path="/staff-dashboard" element={
              <StaffDashboard />
            }
            />
            <Route path="/patch-list" element={
              <PatchList />
            }
            />
            <Route path="/management" element={
              <LocManagement />
            }
            />
            <Route path="/reports" element={
              <Reports />
            }
            />
            <Route path="/statistics" element={
              <Statistics />
            }
            />
            <Route path="/staff" element={
              <Staff />
            }
            />
            <Route path="/objType" element={
              <ObjectType />
            }
            />
            <Route path="/thankYou" element={
              <ThankYou />
            }
            />
            <Route path="/cameraPatch" element={
              <CameraPatch />
            }
            />
            <Route path="/profile" element={
              <Profile />
            }
            />
            <Route path="/test" element={

              <Test />

            }
            />

          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
