import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user from '../../../assets/imgs/user.svg';
import US from '../../../assets/imgs/flags-rectangle/us.svg';
import Brazil from '../../../assets/imgs/flags-rectangle/br.svg';
import { getCurrentUserFN } from '../../../utils/request';
import { useQuery } from "@tanstack/react-query"
import Profile from "../../../assets/imgs/Profile.jpg"
import authAxios from '../../../utils/APIInstance';
import i18n from '../../../i18n';
import logo from '../../../assets/company/patch.png';
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



const lngs = [
    { code: 'en', nativeName: 'English', flag: US },
    { code: 'pt', nativeName: 'Português', flag: Brazil },
    { code: 'fallback', nativeName: 'English', flag: US },
];

function Navbar(props) {

    const { t } = useTranslation()
    const navigate = useNavigate();

    let definedLanguage = false

    const current = props.current

    const [dropdown, setDropdown] = useState(-1)
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const { data: current_user } = useQuery(["user"], getCurrentUserFN)

    const navigation = [
        { name: t('Navigation.Sidebar.Dashboard'), icon: HomeIcon, href: './dashboard', current: true },
        { name: t('Navigation.Sidebar.Staff'), icon: UsersIcon, href: './staff', current: false },
        { name: t('Navigation.Sidebar.Manage'), icon: FolderIcon, href: './management', current: false },
        { name: t('Navigation.Sidebar.Object_Types'), icon: FolderIcon, href: './objType', current: false },
        { name: t('Navigation.Sidebar.To_Patch'), icon: CalendarIcon, href: './patch-list', count: 11, current: false },
        { name: t('Navigation.Sidebar.Reports'), icon: InboxIcon, href: './reports', current: false },
        { name: t('Navigation.Sidebar.Statistics'), icon: ChartBarIcon, href: './statistics', current: false },
    ]

    const Logout = async (e) => {
        e.preventDefault();
        try {
            const response = await authAxios.post('/auth/logout/');
            if (response.status === 200) {
                navigate('/sign-in');
            }
        } catch (error) {
            alert("An error Occurred")
        }
    }


    return (
        <div className='lg:ml-64 mb-16'>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col border-gray-200 pt-5 pb-4 bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <FontAwesomeIcon icon={"fa-solid fa-times"} className="text-gray-300 text-2xl  " />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex items-center flex-shrink-0 px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src={logo}
                                        alt="QRpatch"
                                    />
                                </div>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 divide-y divide-gray-300 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className='flex flex-col h-full'>
                                        <div className="space-y-1 md:space-y-3 px-2">
                                            {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        current === item.name ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center px-2 py-2 text-sm md:text-lg font-medium rounded-md'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-3 flex-shrink-0 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span className="flex grow justify-start">{item.name}</span>
                                                    <div className=''>
                                                        {item.count ? (
                                                            <span
                                                                className={classNames(
                                                                    item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200 ',
                                                                    'ml-3 py-0.5 flex px-3 text-xs md:text-lg font-medium rounded-full'
                                                                )}
                                                            >
                                                                {item.count}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </a>
                                            ))}
                                        </div>
                                        <div className="flex grow mb-14 items-end p-4">
                                            <a href="#" className="group block w-full flex-shrink-0 border-t-2 ">
                                                <div className="flex items-center pt-5">
                                                    <div>
                                                        <img
                                                            className="inline-block h-9 w-9 md:h-14 md:w-14 rounded-full"
                                                            src={current_user?.photo_file ? current_user?.photo_file : user}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm md:text-lg font-medium text-black">{current_user?.first_name} {current_user?.last_name}</p>
                                                        <p className="text-xs md:text-base font-medium text-gray-600 group-hover:text-gray-800">View profile</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* TODO: Avoid hardcoding the padding of the navbar
    I was having problems with the navbar width, so I hardcoded the padding value as a quickfix 
    Suspected issue: Hardcoded margin value of 64 to compensate for the sidebar*/}
            <nav className="bg-white flex items-center shadow-md h-16 lg:pl-10 xl:pr-72 fixed z-20 top-0 w-full">
                <div className='flex flex-none items-center'>
                    <button
                        type="button"
                        className=" px-8 text-gray-400 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <FontAwesomeIcon icon="fa-solid fa-bars" className="text-gray-800 text-2xl  " />
                    </button>
                    <FontAwesomeIcon icon="fa-solid fa-cog" className='text-gray-600 p-2 rounded-full hover:bg-gray-200 cursor-pointer' />
                </div>
                <div className='flex-grow'>

                </div>
                <div className='flex-none flex justify-center items-center gap-4'>
                    {lngs.map((lng) => {
                        if (!definedLanguage && (lng.code == i18n.language || lng.code === 'fallback')) {
                            definedLanguage = true;
                            return (
                                <div className='flex p-5 md:p-2 rounded-full hover:bg-gray-200 items-center justify-center cursor-pointer' onClick={() => setDropdown((dropdown !== 1) ? 1 : -1)}>
                                    <img src={lng.flag} alt="flag" className='h-4 pr-2'></img>
                                    <h2 className='font-semibold'>{(lng.nativeName)}</h2>
                                </div>
                            )
                        }
                    })}
                    {/* <!-- Language Dropdown menu --> */}
                    <div className={dropdown === 1 ? "z-10 absolute bg-white mt-44 mr-24 md:mr-64 divide-y divide-gray-100 rounded-lg shadow w-44" : "z-10 hidden bg-white mt-44 mr-64 divide-y divide-gray-100 rounded-lg shadow w-44"}>
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            {lngs.map((lng) => {
                                if (lng.code !== 'fallback') {
                                    return (
                                        <li className='hover:cursor-pointer'>
                                            <a onClick={() => (i18n.changeLanguage(lng.code), setDropdown((dropdown !== 1) ? 1 : -1), localStorage.setItem('language', lng.code))} key={lng.code} className="block px-6 py-2 hover:bg-gray-100"><img src={lng.flag} className=' inline-block h-4 pr-2 mb-1'></img>{(lng.code.toUpperCase())}</a>
                                        </li>
                                    )
                                }
                            })}

                        </ul>
                    </div>
                    <FontAwesomeIcon icon="fa-solid fa-bell" className='hidden md:flex text-gray-600 p-1 rounded-full hover:bg-gray-200 cursor-pointer' />
                    <div className="hidden md:flex items-center p-1 rounded-full hover:bg-gray-200 cursor-pointer" onClick={() => setDropdown((dropdown != 2) ? 2 : -1)} >

                        <div className='pr-2 '>
                            <div className="flex items-center">
                                <img
                                    className="h-8 w-8 rounded-full sm:hidden"
                                    src={user}
                                    alt=""
                                />
                                <h1 className="ml-3 text-md font-bold leading-7 text-gray-900  sm:truncate">
                                    {current_user?.first_name} {current_user?.last_name}
                                </h1>
                            </div>
                            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                <dt className="sr-only">Company</dt>
                                <dd className="flex items-center justify-end w-full text-xs text-gray-500 font-medium capitalize">

                                    {current_user?.is_staff ? "Admin" : "Member"}
                                </dd>
                            </dl>
                        </div>
                        <img
                            className="hidden h-9 w-9 sm:block ml-2 rounded-full"
                            src={current_user?.photo_file ? current_user?.photo_file : user}
                            alt=""
                        />
                    </div>
                    {/* <!-- Profile Dropdown menu --> */}
                    <div className={dropdown === 2 ? "z-10 absolute bg-white mt-64 ml-32 divide-y divide-gray-100 rounded-lg shadow w-44" : "z-10 hidden bg-white mt-64 ml-32 divide-y divide-gray-100 rounded-lg shadow w-44"}>
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            <li>
                                <a href="./profile" className="block px-4 py-2 hover:bg-gray-100">{t('Buttons.Profile')}</a>
                            </li>
                            <li>
                                <a href="./profile" className="block px-4 py-2 hover:bg-gray-100">{t('Buttons.Settings')}</a>
                            </li>
                            <li>
                                <a href="#" className="block px-4 py-2 hover:bg-gray-100">{t('Buttons.Change_Password')}</a>
                            </li>
                        </ul>
                        <div className="py-2 flex justify-center">
                            <button onClick={Logout} className="block px-4 py-2 text-sm text-red-700 hover:text-red-900"> {t('Buttons.Sign_Out')}</button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;