import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/company/QR PATCH ICON ONLY.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../../context/authContext';
import { useTranslation } from 'react-i18next';
import authAxios from '../../utils/APIInstance';
import axios from 'axios';


function Login() {

    const { t } = useTranslation()
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.floating_email.value;
        const password = e.target.floating_password.value;

        try {
            const response = await authAxios.post('/auth/login/', {
                email,
                password,
            });
            if (response.status === 200) {
                setIsAuthenticated(true);
                navigate('/dashboard');
            }
        } catch (error) {
            alert("Credentials are Invalid")
        }
    };

    return (
        <>
            <div className='w-screen h-screen flex flex-col justify-center items-center'>
                <div>
                    <img src={logo} alt="QRpatch" className='logo h-16'></img>
                </div>
                <div className=''>
                    <h4 className='mt-8 mb-40 font-display text-3xl text-gray-700 font-comfortaa text-center'>{t('Titles.Welcome')}!</h4>

                </div>
                <div className='2xl:w-3/12 xl:w-4/12 justify-center mx-auto' >
                    <form onSubmit={handleSubmit}>
                        <div className="relative z-0 mb-8 text-left w-full group">
                            <input
                                type="email"
                                name="floating_email"
                                id="floating_email"
                                className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                placeholder=" "
                                required="" />
                            <label
                                htmlFor="floating_email"
                                className="peer-focus:font-medium absolute text-sm text-left text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-user" />   {t('Inputs.Email')}
                            </label>
                        </div>
                        <div className='flex'>
                            <div className="relative z-0 mb-16 w-full text-left group grow">
                                <input
                                    type="password"
                                    name="floating_password"
                                    id="floating_password"
                                    className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                    placeholder=" "
                                    required="" />
                                <label
                                    htmlFor="floating_password"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-lock" />  {t('Inputs.Password')}
                                </label>
                                <a className='text-xs font-medium mt-2 grid justify-items-end text-gray-600 hover:text-gray-800 cursor-pointer '>
                                    {t('Buttons.Forgot_Password')}
                                </a>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="px-5 py-2.5 w-full mb-10 outline-1 outline text-sm rounded-full hover:bg-stone-400 outline-stone-400 focus:ring-4 focus:outline-none focus:ring-stone-300"
                        >
                            {t('Buttons.Login')}
                        </button>

                        <a href="./sign-up" className='cursor-pointer text-zinc-500 hover:text-zinc-700'>
                            {t('Buttons.Dont_Have_One')}
                        </a>
                    </form>
                </div>

            </div>



        </>
    );
}

export default Login;