import authAxios from './APIInstance';

export const getAllUsersFn = async () => {
    const result = await authAxios.get(`/api/users/`);
    return result.data

}

export const getUserClientFn = async () => {
    const result = await authAxios.get(`/api/userAndClient/`);
    return result.data

}

export const getRootLocationsFn = async () => {
    const result = await authAxios.get(`/api/locations/root/`);
    return result.data

}

export const getAllLocationsFn = async () => {
    const result = await authAxios.get(`/api/locations/client/`);
    return result.data

}

export const getSpecificLocationsFn = async (endpoint) => {
    const result = await authAxios.get(`/api/${endpoint}/`);
    return result.data

}

export const getToPatchFN = async () => {
    const result = await authAxios.get(`/api/toPatch/`);
    return result.data

}


export const getCurrentUserFN = async () => {
    const result = await authAxios.get(`/api/users/get_current_user/`);
    return result.data

}

export const updatePatchFN = async ({ id, userId }) => {
    const result = await authAxios.patch(`/api/occurences/${id}/`, { solved: true, solvedByUserId: userId, solvedAt: new Date() });
    return result.data

}

export const generateReportFn = async (period, type, user, location) => {
    try {
        let url = `/api/generateReport/`;
        url += `?period=${period}`;
        if (type) {
            url += `&type=${type}`;
        }
        if (user) {
            url += `&user=${user}`;
        }
        if (location) {
            url += `&location=${location}`;
        }
        const result = await authAxios.get(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}


export const createLocationFn = async ({ name, parent, client }) => {
    const result = await authAxios.post(`/api/locations/`, { name: name, parent: parent, client: client, defaultQrLanguage: 2 });
    return result.data

}


export const getAvailableUsersFn = async (userName) => {
    try {
        let url = `/api/addUser/`;
        if (userName) {
            url += `?query=${userName}`;
        }
        const result = await authAxios.get(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}

export const getSelectionOfObjTypesFn = async (objType) => {
    try {
        let url = `/api/selectObjectType/`;
        if (objType) {
            url += `?query=${objType}`;
        }
        const result = await authAxios.get(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}

export const getProblemsFn = async (problem, objType) => {
    try {
        let url = `/api/selectProblem/`;
        url += `?query=${problem}`;
        if (objType) {
            url += `&objType=${objType}`;
        }
        const result = await authAxios.get(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}

export const createObjectFn = async ({ objType, location, extra_info }) => {
    const result = await authAxios.post(`/api/createObject/`, { objType: objType, location: location, extra_info: extra_info });
    return result.data

}


export const getObjectsInLocation = async (location) => {
    try {
        let url = `/api/objectsInLocation/`;
        if (location) {
            url += `?location=${location}`;
        }
        const result = await authAxios.get(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}

export const getUsersOfClientFn = async () => {
    const result = await authAxios.get(`/api/usersOfClient/`);
    return result.data

}


export const createUserClientFn = async ({ user, client }) => {
    const result = await authAxios.post(`/api/createUserClient/`, { client: client, user: user });
    return result.data

}

export const createUserFn = async ({ first_name, last_name, email, password }) => {
    const result = await authAxios.post(`/api/createUser/`, { first_name: first_name, last_name: last_name, email: email, password: password, is_active: true });
    return result.data

}


export const getClientObjectTypesFn = async () => {
    const result = await authAxios.get(`/api/objectTypes/client/`);
    return result.data

}


export const createScan = async ({ problem, object }) => {
    try {
        let url = `/api/scans/`;
        if (problem) {
            url += `?problem=${problem}`;
        }
        if (object) {
            url += `&object=${object}`;
        }
        const result = await authAxios.post(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}



export const createObjectTypeWithProblemsFn = async ({ name, problems, icon, client }) => {
    const objectTypeData = { name, icon };
    const problemsData = problems.map(problemName => ({ name: problemName, client }));
    const result = await authAxios.post(`/api/createObjectType/`, { object_type: objectTypeData, problems: problemsData });

    return result.data.object_type;
}

export const getUnsolvedOccurenceFN = async (problem, object) => {
    try {
        let url = `/api/occurences/unsolved_occurence/`;
        if (problem) {
            url += `?problem=${problem}`;
        }
        if (object) {
            url += `&object=${object}`;
        }
        const result = await authAxios.get(url);
        return result.data;
    } catch (error) {
        console.error(error);
    }

}


export const updateUserAvatarFn = async (file) => {
    const formData = new FormData();
    formData.append('photo_file', file);
    const result = await authAxios.patch(`/api/users/update_avatar/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
    return result.data;
}