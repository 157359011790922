import React, { useState } from 'react';
import logo from '../../assets/company/patch.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import patch from '../../assets/company/QRpatch.png';
import problem from '../../assets/imgs/problem.png';
import solution from '../../assets/imgs/solution.png';
import gift from '../../assets/imgs/gift.svg';
import treasure from '../../assets/imgs/treasure.svg';
import powerful from '../../assets/imgs/powerful.svg';
import money from '../../assets/imgs/money.svg';
import smile from '../../assets/imgs/smile.svg';
import saveTime from '../../assets/imgs/save-time.svg';
import i18n from '../../i18n';
import US from '../../assets/imgs/flags-rectangle/us.svg';
import Brazil from '../../assets/imgs/flags-rectangle/br.svg';
import { useTranslation } from 'react-i18next';
import newLogo from '../../assets/company/QR PATCH LOGO HORIZONTAL REVERSED.png'
import Navbar from '../../components/Public/navbar';
import Footer from '../../components/Public/footer';





function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}




function Home(props) {

    const { t } = useTranslation()

    let definedLanguage = false

    const [dropdown, setDropdown] = useState(-1)


    const [show, setShow] = useState(false)

    return (
        <div className='overflow-x- bg-gradient-to-r from-stone-700 to-stone-800' >
            <Navbar />
            <div className=''>
                <div className="relative isolate overflow-hidden -my-16">
                    <svg
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
                    </svg>
                    <div
                        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#1e1e1f] to-[#710e0e] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-52 lg:flex lg:px-8 lg:pt-40">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                            <h1 className="mt-28 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                                {t('Public.Home.Header')}
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                {t('Public.Home.SubHeader')}
                            </p>
                            <div className="mt-10 flex justify-center md:justify-start items-center gap-x-6">
                                <a
                                    href="./sign-up"
                                    className="rounded-md bg-gradient-to-r from-red-900 to-orange-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm animate-pulse hover:animate-none"
                                >
                                    {t('Buttons.Get_Started_Now')}
                                </a>
                                <a href="./about" className="text-sm font-semibold leading-6 text-gray-200">
                                    {t('Buttons.Learn_More')} <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                                <img
                                    src={patch}
                                    alt="App screenshot"
                                    width={50}
                                    height={50}
                                    className="w-[30rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full bg-stone-200 md:px-24'>
                    <div className='container mx-auto px-6 py-16'>
                        <div className='flex flex-col'>
                            <h1 className='text-3xl mb-4'> {t('Public.Home.What_Is_QRpatch')}</h1>
                            <div className='h-1 w-64 bg-gradient-to-r from-red-900 to-orange-700 mx-auto mb-20 lg:mb-20'></div>
                            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                                <div className="lg:w-full lg:max-w-2xl lg:flex-auto text-left">
                                    <p className="text-xl leading-8 text-gray-600 order-1">
                                        <b> {t('Public.Home.text1')}</b>
                                    </p>
                                    <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 order-2">
                                        <p>
                                            {t('Public.Home.text2')}
                                        </p>
                                        <p className="mt-10">
                                            {t('Public.Home.text3')}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-center lg:flex lg:flex-auto lg:justify-center order-3">
                                    <img className="space-y-10 w-3/4 aspect-auto -translate-y-11" src={problem}>
                                    </img>
                                </div>
                            </div>
                            <div className="xl:mt-20 mb-32 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                                <div className="flex justify-center lg:flex lg:flex-auto lg:justify-center order-5 md:order-4">
                                    <img className="w-3/4 space-y-10 aspect-auto " src={solution}>
                                    </img>
                                </div>
                                <div className="lg:w-full lg:max-w-2xl lg:flex-auto text-left order-4 md:order-5">
                                    <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                                        <p>
                                            {t('Public.Home.text4')}
                                        </p>
                                        <p className="mt-10">
                                            {t('Public.Home.text5')}
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <a href='./sign-up' className='text-2xl md:text-2xl mb-32 font-semibold italic order-7'>{t('Public.Home.text6')}<span className='bg-gradient-to-r from-rose-700 to-orange-700 text-white px-2 rounded-md'>{t('Public.Home.text7')}</span></a>



                            <h1 className='text-2xl md:text-3xl mb-4 order-8'>{t('Public.Home.Benefits_Of_A_Collaborative_Maintenance')}</h1>
                            <div className='h-1 w-9/12 md:w-5/12 bg-gradient-to-r from-red-900 to-orange-700 mx-auto mb-20 lg:mb-36 order-9'></div>
                            <div className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 justify-center mx-auto gap-6 md:gap-4 mb-10 md:mb-20 order-10'>
                                <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md md:col-span-2 lg:col-span-1">
                                    <a href="#">
                                        <img src={saveTime} alt="Save Time" className="max-h-28 flex justify-center w-full mb-4"></img>
                                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-9">{t('Public.Home.Save_Time')}</h5>
                                    </a>
                                    <p className="mb-3 font-normal text-gray-700">{t('Public.Home.Save_Time_Text')}</p>
                                    <a href="./about" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-gradient-to-r from-red-900 to-orange-700 rounded-lg hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {t('Buttons.Read_More')}
                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </a>
                                </div>
                                <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md md:col-span-2 lg:col-span-1">
                                    <a className href="#">
                                        <img src={money} alt="Save Money" className="max-h-28 flex justify-center w-full mb-4"></img>
                                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-9">{t('Public.Home.Save_Money')}</h5>
                                    </a>
                                    <p className="mb-3 font-normal text-gray-700">{t('Public.Home.Save_Money_Text')}</p>
                                    <a href="./about" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-gradient-to-r from-red-900 to-orange-700 rounded-lg hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {t('Buttons.Read_More')}

                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </a>
                                </div>
                                <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md md:col-start-2 md:col-span-2 lg:col-span-1">
                                    <a href="#">
                                        <img src={smile} alt="Smiley Face" className="max-h-28 flex justify-center w-full mb-4"></img>
                                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-9">{t('Public.Home.Keep_Customers_Satisfied')}</h5>
                                    </a>
                                    <p className="mb-3 font-normal text-gray-700">{t('Public.Home.Keep_Customers_Satisfied_Text')}</p>
                                    <a href="./about" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-gradient-to-r from-red-900 to-orange-700 rounded-lg hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {t('Buttons.Read_More')}

                                        <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Home;