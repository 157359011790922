import { Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useState, useRef } from "react";
import { updatePatchFN, getCurrentUserFN, getUnsolvedOccurenceFN } from "../../../../utils/request";
import { useMutation, useQueryClient } from "@tanstack/react-query";


function QRScanner() {

    const [scanResult, setScanResult] = useState(null)
    const scannerRef = useRef(null);
    const timeoutRef = useRef(null);

    const queryClient = useQueryClient();
    const { mutate } = useMutation(updatePatchFN, {
        onSuccess: () => {
            queryClient.invalidateQueries(["toPatch"])
        },
    });



    useEffect(() => {
        // Timeout to give any previous scanner instance time to clear
        const timeoutId = setTimeout(() => {
            scannerRef.current = new Html5QrcodeScanner('reader', {
                qrbox: {
                    width: 250,
                    height: 250,
                },
                fps: 10,
            });

            scannerRef.current.render(success, error);

            async function success(result) {
                scannerRef.current.clear();
                try {
                    const url = new URL(result);
                    if (url.hostname !== '127.0.0.1' || url.port !== '3000' || url.pathname !== '/thankYou') {
                        throw new Error('Invalid QRpatch');
                    }
                    const params = url.searchParams;
                    const problemId = params.get('problem');
                    const objectId = params.get('object');
                    if (!problemId || !objectId) {
                        throw new Error('Invalid QRpatch');
                    }
                    // Get user data
                    const userData = await getCurrentUserFN();
                    const userId = userData?.id

                    try {
                        const occurence = await getUnsolvedOccurenceFN(problemId, objectId);
                        // console.log("You idiot: " + occurence.occurence_id)
                        // Mark problem as solved
                        mutate({ id: occurence.occurence_id, userId: userId });
                        setScanResult('Problem has been marked as solved!');
                    } catch {
                        // console.log("You !so idiot: " + occurence.occurence_id)
                        setScanResult("This QRpatch has no pending occurences")
                    }

                } catch (err) {
                    setScanResult(err.message);
                }


                // Clear the result after 10 seconds
                timeoutRef.current = setTimeout(() => {
                    setScanResult(null);
                    scannerRef.current.render(success, error);
                }, 10000);
            }


            function error(err) {
                console.warn(err);
            }
        }, 500);  // Half a second delay

        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
            if (scannerRef.current) {
                scannerRef.current.clear();
            }
        }
    }, []);

    return (
        <div className="flex justify-center" >
            {scanResult ? <div className="relative">{scanResult}</div> : null}

            <style>{`
        #reader__scan_region {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }
    `}</style>

            <div id="reader" className=" justify-center items-center h-96 w-96 m-0" hidden={scanResult} > </div>
        </div >
    )

}

export default QRScanner;